import { Agenda, Inject, Month, ScheduleComponent, ViewDirective, ViewsDirective } from '@syncfusion/ej2-react-schedule'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
//import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import { Container, Paper, Card } from '@mui/material'
import { L10n } from '@syncfusion/ej2-base'
import Modal from '../UI/ErrorDialog'
import './LB.css'
import useZustandToken from '../useZustandToken'
import { debug } from '../../utils/ConsoleHelper'
import { registerLicense } from '@syncfusion/ej2-base';
import { TurnLeftOutlined } from '@mui/icons-material'
import { getData } from '../../utils/dbHelper'
import { dbCalenderAdd, dbCalenderModify } from '../../api/db'

// "ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBhX35YdX1XRGddUk0="
registerLicense(
	'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkJiWHpecH1QQmRY'
	// 'Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9edHRdQ2lfWUd3V0A='
	// 'Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jQX9Sd0JnUXtfd3FXQw==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TfkRiWHhaeHBUTmJVVA==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdk1iWH5ZdHxRR2hYUkE=;MjYzODMxOUAzMjMwMmUzNDJlMzBjZjhORk96dEF6Q2E2aHdyckNNNVdLOFVNTGpwbXdCN2FlZlFlMkZNbVVrPQ==;MjYzODMyMEAzMjMwMmUzNDJlMzBtZDBBcVJMNFZtYWgyQ05DZW5FQ3dZNTF1Ui9FeTRKcWZiSTQrUm5QQVhzPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVrWH5fd3BdQmFVU0Vz;MjYzODMyMkAzMjMwMmUzNDJlMzBGeWtDUjlscVRUbTJLQW5MaWcrWVdIVVR0cVEzK0dkZFJ3blJBVm1RLzdNPQ==;MjYzODMyM0AzMjMwMmUzNDJlMzBTeGV1aWg1TGt5WXgrK3VadGkzNlZoYkR3YWtrMlZnSlVaeFJOTUsrcU9RPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdk1iWH5ZdHxRR2lUWEE=;MjYzODMyNUAzMjMwMmUzNDJlMzBtYzREY0IzM3JEZ282Vm02eko5Qnc5VVdwMmQzV1lCeW9DTXlvZFhtZE9nPQ==;MjYzODMyNkAzMjMwMmUzNDJlMzBmSFRCeVUwUS9zZjM0S2lEWEVwWk5ybGNWZGRXSys3WXlOOW5yM1dGTFMwPQ==;MjYzODMyN0AzMjMwMmUzNDJlMzBGeWtDUjlscVRUbTJLQW5MaWcrWVdIVVR0cVEzK0dkZFJ3blJBVm1RLzdNPQ=='
);

L10n.load({
	'en-US': {
		schedule: {
			saveButton: 'Submit',
			cancelButton: 'Close',
			deleteButton: 'Delete',
			newEvent: 'Book Schedule',
		},
	},
})

const CalendarPage = (props) => {

	const { token } = useZustandToken()
	//const navigate = useNavigate();
	const reservation_title = 'Reservation Calendar'
	//const reservation_overlap = 'The selected date(s) not available or overlapped with existing reservation!';
	const reservation_availability = `Cannot determine availability! Please try again!`
	const [calendarUpdated, setCalendarUpdated] = useState()
	const [modalOpen, setModalOpen] = useState(false)
	const [errorheader, setErrorHeader] = useState()
	const [errorstr, setErrorStr] = useState()
	const [allEvents, setAllEvents] = useState()
	const [modified, setModified] = useState()
	const [machineid, setMachineId] = useState()
	const [machinedetails, setMachineDetails] = useState()
	const [rawData, setRawData] = useState()
	let scheduleObj

	useEffect(() => {
		async function retrievedate() {
			const data = await getData('Select * from CALENDAR')
			setAllEvents(data)
		}
		retrievedate()
	}, [])
	//// refresh the calendar
	function refreshPage() {
		//// hack to refresh the page - for some reason the calendar does not upate correctly when data changed!
		//// does not work here since it will clear the selected manufacturer and serial number list
		//window.location.reload(false);
	}


	const openErrorDlg = (headerstr, errorstr) => {
		setErrorHeader(headerstr)
		setErrorStr(errorstr)
		setModalOpen(true)
	}

	//// The onCellClick and onEventClick will allow editing/creating event with one-click instead double-click
	const onCellClick = (args) => {
		scheduleObj?.openEditor(args, 'Add')
	}
	const onEventClick = (args) => {
		if (!args.event.RecurrenceRule) {
			scheduleObj?.openEditor(args.event, 'Save')
		} else {
			//scheduleObj.openEditor(args.event, 'Edit');
			scheduleObj?.quickPopup.openRecurrenceAlert()
		}
	}

	const onPopupOpenHandler = (args) => {
		debug(args)
		let oktomod = true;
		if (args.type === 'Editor') {
			args.duration = 60
		}

		if (args.type === 'QuickInfo') {
			// This change from quick to detail info
			var dialogObj = args.element.ej2_instances[0]
			//dialogObj.hide()
			//var currentAction = args.target.classList.contains('e-work-cells') ? 'Add' : 'Save'
			//// send out cancel
			scheduleObj?.openEditor(args.data, 'Cancel')
		} else if (args.type === 'Editor' || args.type === 'DeleteAlert' || args.type === 'RecurrenceAlert') {
			var dialogObj = args.element.ej2_instances[0]
			var currentAction = args.target?.classList.contains('e-work-cells') ? 'Add' : 'Save'
			if ((
				currentAction === 'Add'
				// || currentAction === 'Save'
			)
				&& (token && token?.token !== "admin")) {
				args.cancel = true;
				alert("You don't have admin right!")
			}
			return
		}
		
		if (args && args.type === 'DeleteAlert') {
			debug("Delete Alert")
		}
		setModified(false)
		
		if (args && args?.requestType === 'toolbarItemRendering') {
		} else {
			/*
			//// on the lab calendar - it is hard to determine the overlap event b/c there might be
			//// multiple events for different serial
			let availability = true
			if (args.data) {
				let downers = []
				downers.push('')
				// if oktomod is true then the entry can be deleted/modified
				setCalendarUpdated(false)
				if (args?.addedRecords.length > 0) {
					const bookinginfo = {
						start: new Date(args.addedRecords[0].start),
						end: new Date(args.addedRecords[0].end),
						location: '',
						title: args.addedRecords[0].subject,
						description: args.addedRecords[0].description,
						email: args.addedRecords[0].email,
					}
					AddBooking(bookinginfo, args)
	
				} else if (args?.changedRecords.length > 0) {
					if (!scheduleObj) {
						openErrorDlg(reservation_title, reservation_availability)
						args.cancel = true
					} else {
						const bookinginfo = {
							id: args.changedRecords[0].id || -1,
							start: new Date(args.changedRecords[0].start),
							end: new Date(args.changedRecords[0].end),
							title: args.changedRecords[0].subject,
							description: args.changedRecords[0].description,
							email: args.changedRecords[0].email,
						}
						ModifyBooking(bookinginfo, args)
						setCalendarUpdated(true)
					}
				} else if (args?.deletedRecords.length > 0) {
					if (!oktomod && !admin) {
						openErrorDlg(reservation_title, 'You are not the owner of this reservation.')
						args.cancel = true
					} else {
						const bookinginfo = {
							id: args.deletedRecords[0].id || -1,
							start: new Date(args.deletedRecords[0].start),
							end: new Date(args.deletedRecords[0].end),
						}
						DeleteBooking(bookinginfo, args)
					}
				}
			}
			*/
		}
	}

	const onActionComplete = (args) => {
		debug(args)
		if (args.requestType === 'toolBarItemRendered' ||
			args.requestType === 'dateNavigate') // || args.name === 'actionComplete')
			return;

		const oktomod = true

		if (args.requestType === 'eventCreated' || args.requestType === 'eventChanged' || args.requestType === 'eventRemoved') {
			if (args?.addedRecords.length > 0) {
				if (token && token?.token !== "admin") {
					openErrorDlg(reservation_title, 'You are not the admin user.')
					args.cancel = true
				} else {

					const end = args.addedRecords[0]?.end || ""
					const bookinginfo = {
						Id: args.addedRecords[0]?.Id,
						description: args.addedRecords[0]?.description,
						email: args.addedRecords[0].email,
						end: new Date(end),
						EndTimeZone: args.addedRecords[0]?.EndTimeZone || "",
						RecurrenceRule: args.addedRecords[0]?.RecurrenceRule || "",
						StartTime: args.addedRecords[0]?.StartTime || "",
						StartTimeZone: args.addedRecords[0]?.StartTimeZone || "",
						subject: args.addedRecords[0]?.subject || "",
						IsAllDay: args.addedRecords[0]?.IsAllDay || "",
					}
					AddBooking(bookinginfo, args)
				}

			} else if (args?.changedRecords.length > 0) {
				if (token && token?.token !== "admin") {
					openErrorDlg(reservation_title, 'You are not the admin user.')
					args.cancel = true
				} else {
					if (!scheduleObj) {
						openErrorDlg(reservation_title, reservation_availability)
						args.cancel = true
					} else {
						const bookinginfo = {
							Id: args.changedRecords[0]?.Id,
							description: args.changedRecords[0]?.description,
							email: args.changedRecords[0].email,
							end: new Date(args.changedRecords[0]?.end),
							StartTime: new Date(args.changedRecords[0]?.StartTime),
							EndTimeZone: args.changedRecords[0]?.EndTimeZone || "",
							RecurrenceRule: args.changedRecords[0]?.RecurrenceRule || "",
							StartTimeZone: args.changedRecords[0]?.StartTimeZone || "",
							subject: args.changedRecords[0]?.subject || "",
							IsAllDay: args.changedRecords[0]?.IsAllDay || "",
						}
						ModifyBooking(bookinginfo, args)
						setCalendarUpdated(true)
					}
				}
			} else if (args?.deletedRecords.length > 0) {
				if ((token && token?.token !== "admin")) {
					openErrorDlg(reservation_title, 'You are not the owner of this reservation.')
					args.cancel = true
				} else {
					const recurrenceRule = args.deletedRecords[0]?.RecurrenceRule || ""
					const startTime = args.deletedRecords[0]?.StartTime || ""
					const start = args.deletedRecords[0]?.start || ""
					const end = args.deletedRecords[0]?.end || ""
					const bookinginfo = {
						id: args.deletedRecords[0].id || -1,
						start: new Date(startTime),
						end: new Date(end),
					}
					DeleteBooking(bookinginfo, args)
				}
			}
		}
	}

	const AddBooking = async (data, args) => {
		if (token && token?.token !== "admin") {
			openErrorDlg(reservation_title, 'You are not the admin user.')
			args.cancel = true
		} else {
			await dbCalenderAdd(data)
		}
	}

	const ModifyBooking = async (data, args) => {
		if (token && token?.token !== "admin") {
			openErrorDlg(reservation_title, 'You are not the admin user.')
			args.cancel = true
		} else {
			await dbCalenderModify(data)
		}
	}

	const DeleteBooking = (data, args) => {
	}

	const onActionBegin = (e) => {
	}

	debug(allEvents)

	return (
		<Card className="table">
			<Container maxWidth="xl">
				{props.label}
				<h6 style={{ color: 'blue', paddingBottom: '10px' }}> Event</h6>
				{/* {!allEvents && <Card> Please select the Labname</Card>}
               {allEvents && ( */}
				<ScheduleComponent
					rowAutoHeight={true}
					delayUpdate="true"
					cssClass="day-view-customize"
					//cssClass="schedule-overview"
					height="550px"
					currentView="Month"
					ref={(schedule) => (scheduleObj = schedule)}
					popupOpen={onPopupOpenHandler}
					actionBegin={(event) => onActionBegin(event)}
					onActionBegin={(event) => onActionBegin(event)}
					actionComplete={(event) => onActionComplete(event)}
					showQuickInfo={false}
					eventClick={onEventClick}
					cellClick={onCellClick}
					selectedDate={new Date()}
					IsAllDay={false}
					// This is the way to change their default to our own name/key
					eventSettings={{
						dataSource: allEvents,
						fields: {
							id: 'Id',
							subject: {
								name: 'subject',
								title: 'Title',
								validation: { required: true },
							},
							location: {
								name: 'email',
								title: 'Email',
								default: `hienqngo@yahoo.com`,
								validation: { required: true },
							},
							description: {
								name: 'description',
								title: 'Description',
								default: `Day to remember`,
								title: 'Description',
								validation: { required: true },
							},
							endTime: {
								name: 'end',
								title: 'End Time',
								validation: { required: true },
							},
						},
					}}
				>
					<ViewsDirective>
						<ViewDirective option="Month"></ViewDirective>
						<ViewDirective option="Agenda"></ViewDirective>
					</ViewsDirective>
					<Inject services={[Month, Agenda]} />
				</ScheduleComponent>
				{/* )} */}
				{modalOpen && <Modal setOpenModal={setModalOpen} headerstr={errorheader} bodystr={errorstr} />}
			</Container>
		</Card>
	)
}

export default CalendarPage
