import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import People from "./components/views/People";
import Tree from "./components/views/Tree";
import FrontPage from "./components/views/FrontPage";
import { NotFound } from "./components/views/NotFound";
import Navigation from "./components/UI/Navigation";
import { ContactForm } from "./components/views/Contact";
import AddIndividual from "./components/views/AddIndividual";
import Individual from "./components/views/Individual";
import Login from "./components/views/Login";
import { Beforeunload } from "react-beforeunload";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css"; // core css
import useZustandToken from "./components/useZustandToken";
import PreviewDirectoryImages from "./components/views/PreviewDirectoryImages";
import Posts from "./components/views/Posts";
import { LoadingUsage } from "./components/views/usage";
import CalendarPage from "./components/views/CalendarPage";

const App = () => {
	const { token, setToken } = useZustandToken();
	useEffect(() => {
		console.log(token);
	}, [token]);
	if (!token?.token) {
		return <Login setToken={setToken} />;
	}
	const removeApplicationData = () => {
		if (window.closed) {
			localStorage.clear();
		}
	};

	return (
		<>
			<Beforeunload onBeforeunload={removeApplicationData}>
				<Navigation />
				<Routes>
					<Route path="/" element={<FrontPage />}></Route>
					<Route path="/people" element={<People />}></Route>
					<Route path="/person" element={<Individual />}></Route>
					<Route path="/person/:id" element={<Individual clearalbum={true} />}></Route>
					<Route
						// exact
						path="/person/:spouseID/:pid"
						element={<Individual />}
					></Route>
					<Route exact path="/tree/:spouseID/:pid" element={<Tree />}></Route>
					<Route
						exact
						path="/tree/:pid/:prootid/:plastname"
						element={<Tree />}
					></Route>
					<Route path="/tree" element={<Tree />}></Route>
					<Route path="/contact" element={<ContactForm />}></Route>
					<Route path="/addperson" element={<AddIndividual />}></Route>
					<Route path="/person/dirimages" element={<PreviewDirectoryImages />}></Route>
					<Route path="/person/posts" element={<Posts />}></Route>
					<Route path="/posts" element={<Posts />}></Route>
					<Route path="/usage" element={<LoadingUsage />}></Route>
					<Route path="/calendar" element={<CalendarPage />}></Route>
					<Route path="*" element={<NotFound />}></Route>
				</Routes>
			</Beforeunload>
		</>
	);
};

export default App;
