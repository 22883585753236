import React, { useEffect, useState } from "react";
import { OrganizationChart } from "primereact/organizationchart";
import { dbQuery } from "../../api/db";
import { CreateDataTree, GetLastnameFromID } from "../../utils/Helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { BuildDataFromRawData } from "../../utils/dbHelper";
import useZustandToken from "../useZustandToken";
import { debug } from "../../utils/ConsoleHelper";
import "./Tree.css"

function Tree() {
	const [rawdata, setRawData] = useState();
	const [tdata, setTData] = useState();
	const [dbdata, setDbData] = useState();
	const [selection, setSelection] = useState();
	const [rootid, setRootId] = useState("NO2");
	const linkdata = useLocation();
	const navigate = useNavigate();
	const [lastname, setLastname] = useState();
	const { pid, plastname, prootid, spouseID } = useParams();
	const { update } = useZustandToken()
	debug("🚀 ~ Tree ~ spouseID:", spouseID);

	const getData = async (tblstr) => {
		let data = [];
		if (tblstr) {
			data = await dbQuery(`Select * from ${tblstr} order by personID ASC`);
			if (data) setRawData(data);
		}
	};

	//// Take care of the route
	//// path="/tree/:pid/:prootid/:plastname"
	useEffect(() => {
		if (spouseID && pid) {
			debug("🚀 ~ useEffect ~ spouseID:", spouseID);
			const lastname = GetLastnameFromID(spouseID);
			setRootId(pid);
			if (lastname) {
				setLastname(lastname);
				getData(`${lastname}`);
			}
		}
	}, [spouseID, pid, update]);

	useEffect(() => {
		if (pid && plastname && prootid) {
			setLastname(plastname);
			setRootId(pid);
		}
	}, [pid, plastname, prootid]);

	useEffect(() => {
		if (!dbdata && lastname) {
			getData(`${lastname}`);
		}
	}, [lastname]);

	useEffect(() => {
		if (dbdata && rootid) {
			//// check to make sure we have the correct dbdata
			if (dbdata.some((e) => e.id === rootid)) {
				const tree = CreateDataTree(dbdata, rootid, selection);
				//// set the selected to trure to have it display different color
				if (selection && selection?.id) {
					tree[0]?.children.map(e => {
						{ } if (e.id === selection.id) {
							e.selected = true
						}
					})
				}

				setTData(tree);
			}
		}
	}, [dbdata, rootid]);

	useEffect(() => {
		if (linkdata.state && linkdata.state.data) {
			debug(linkdata.state.data);
			setLastname(linkdata.state.data.lastname);
			setSelection(linkdata.state.data);
			//if (linkdata.state.data.id) { setRootId(linkdata.state.data.id) }
			if (linkdata.state.data.personID) { setRootId(linkdata.state.data.personID) }
			else { setRootId(linkdata.state.data.parentID) }
			setRawData(linkdata.state.dbdata);
		}
	}, [linkdata]);

	useEffect(() => {
		if (rawdata && rawdata.length > 0) {
			const ddata = BuildDataFromRawData(rawdata);
			setDbData(ddata);
		}
	}, [rawdata]);

	const onChange = (e) => {
		if (e && e.data) {
			debug(e.data);
			const to_tree_link = `../person/${e.data.id}`;
			navigate(to_tree_link, {
				state: {
					data: e.data,
					modify: false,
					viewonly: true,
				},
			});
		}
	};

	const nodeTemplate = (node) => {
		if (node.type === "person") {
			debug("nodeTemplate: " + node.data)
			if (node.selected) {
				node.className = "bg-indigo-500 text-white"
			}
			return (
				<div className="flex flex-column">
					<div className="flex flex-column align-items-center">
						<img
							alt={node.data.name}
							src={node.data.image}
							className={"mb-2 w-2rem h-2rem"}
						/>
						<span className="font-bold mb-2">{node.data.name}</span>
						{/* <span>{node.data?.title}</span> */}
					</div>
				</div>
			);
		}

		return node.label;
	};
	return (
		<>
			{!tdata && <Card>Searching..... family tree for rootID = {rootid}</Card>}
			{tdata && (
				<div className="card overflow-x-auto">
					<OrganizationChart
						value={tdata}
						selectionMode="single"
						selection={selection}
						onSelectionChange={(e) => {
							onChange(e);
							setSelection(e.data);
						}}
						pt={{
							node: ({ context }) => ({
								className: context.selected
									? "border-orange-400 border-round-sm"
									: "border-primary-400 border-round-sm",
							}),
						}}
						nodeTemplate={nodeTemplate}
					/>
				</div>
			)}
		</>
	);
}

export default Tree;
