import { DecodeString, GetMaterialUIDate } from "./Helper";
import image_icon from "../components/assets/person3.png";
import { debug } from "./ConsoleHelper";
import { dbQuery } from "../api/db";

export const getData = async (querystr) => {
	const data = await dbQuery(querystr);
	return data
}

const FormatDate = (dtstr) => {
	const formattedDate = new Date(dtstr).toLocaleDateString()
	//debug(formattedDate)
	return formattedDate;
}

export const BuildDataFromRawData = (rawdata) => {
	if (rawdata && rawdata.length > 0) {
		const ddata = [];
		rawdata?.forEach((e) => {
			const e1 = {
				id: DecodeString(e.personID),
				type: "person",
				className: "bg-teal-500 text-white",
				data: {
					name: e.name,
					title: e.lastname,
					image: image_icon,
				},
				name: e.name,
				label: DecodeString(e.personID),
				lastname: DecodeString(e.lastname),
				expanded: false,
				children: [],
				parentID: DecodeString(e.parentID),
				info: e.personinfo,
				spouseID: e.spouseID,
				spouse: e.spouse,
				deceased: GetMaterialUIDate(e.deceased),
				selected: false,
			};
			//debug("🚀 ~ rawdata?.forEach ~ e1.spouseID:", e1.spouseID);
			ddata.push(e1);
		});
		return ddata;
	}
};
