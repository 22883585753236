import React, { useState, useRef } from "react";
import axios from "axios";

const UploadImage = (props) => {
	const { persondata, setUploading } = props;
	const [setFile] = useState();
	const fileInput = useRef(null);

	async function fetchImageSize(img_url) {
		try {
			let img = new Image();
			img.src = img_url;
			await img.decode();
			let h = img.height;
			let w = img.width;
			return { height: h, width: w };
		} catch (error) {
			console.error(error);
		}
	}

	const handleUploadFileToServer = async (rec) => {
		let resize_file = rec.ofile;
		let body = new FormData();
		body.file = resize_file;
		body.append("image", resize_file);
		let respstr = "";
		await axios({
			method: "post",
			url: process.env.REACT_APP_SERVER_URL + "/upload",
			data: body,
			headers: {
				"Content-Type": "text/html; charset=utf-8",
				id: persondata.id,
				filename: rec.filename,
				filesize: rec.size,
			},
		})
			.then(function (response) {
				//handle success
				console.log(response.status);
				respstr = response.data;
			})
			.catch(function (response) {
				//handle error
				//alert(response);
				respstr = response.data;
			});
		return respstr;
	};

	const filesSelectedHandler = async (e) => {
		//e.stopPropagation();
		setUploading(true);
		let processed = 0;
		let filetoprocess = 0;
		try {
			const { imagesPreviewUrls, setImagesPreviewUrls } = props;
			const files = Array.from(e.target.files);
			filetoprocess = files.length;
			let mlist = imagesPreviewUrls;
			files.forEach((file, index) => {
				const reader = new FileReader();
				reader.onloadend = async () => {
					const result = {
						ofile: file,
						file: reader.result,
						size: file.size,
						filename: file.name,
					};
					const { height, width } = await fetchImageSize(result.file);
					result.height = height;
					result.width = width;
					const respstr = await handleUploadFileToServer(result);
					processed++;
					if (processed >= filetoprocess) {
						setUploading(false);
					}
					if (respstr && respstr?.includes("Success")) {
						mlist = [...mlist, result];
						setImagesPreviewUrls(mlist);
					}
				};
				reader.readAsDataURL(file);
			});
		} finally {
		}
	};
	return (
		<>
			<div className="previewwrapper">
				<div
					className="drop_zone"
					onChange={filesSelectedHandler}
					onClick={() => fileInput.current.click()}
				>
					<p>Upload to photo album ....</p>
					<input
						type="file"
						ref={fileInput}
						hidden
						onChange={filesSelectedHandler}
						accept="image/png, image/jpeg, image/jpg"
						multiple
					/>
				</div>
			</div>
		</>
	);
};

export default UploadImage;
