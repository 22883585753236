import { Menubar } from "primereact/menubar";
import "./Navigation.css";
import { Divider } from "@mui/material";
import TreeIcon from "../assets/family-tree.png";
//import useToken from "../useToken";
import useZustandToken from "../useZustandToken";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
	const navigate = useNavigate();
	//const { token, setToken } = useZustandToken();
	const token = useZustandToken((state) => state.token)
	const setToken = useZustandToken((state) => state.setToken)
	let navlist = [
		{
			label: "Home",
			icon: "pi pi-fw pi-home",
			command: () => {
				window.location.href = "/";
			},
		},
		{
			label: "All",
			icon: "pi pi-fw pi-id-card",
			command: () => {
				window.location.href = "/people";
			},
		},
		{
			label: "Calendar",
			icon: "pi pi-calendar",
			command: () => {
				window.location.href = "/calendar";
			},
		},
		{
			label: "Contact",
			icon: "pi pi-fw pi-phone",
			command: () => {
				window.location.href = "/contact";
			},
		},
	];

	if (token && token.token === 'admin') {
		navlist = [
			...navlist,
			{
				label: "Help",
				icon: "pi pi-fw pi-question-circle",
				command: () => {
					window.location.href = "/usage";
				},
			},
		]
	}

	const Logout = (e) => {
		setToken({ token: undefined });
		// this will relood the page
		window.location.reload(false);
	};

	const start = (
		<img alt="logo" src={TreeIcon} height="30" className="mr-0"></img>
	);

	const end = (
		<div className="flex align-items-center gap-2 box-border">
			<Divider orientation="vertical" variant="middle" flexItem />
			<h4>{token?.token}</h4>
			<Divider orientation="vertical" variant="middle" flexItem />
			{token?.token && <button onClick={Logout}>Logout</button>}
		</div>
	);

	return (
		<div className="card">
			<Menubar model={navlist} start={start} end={end} />
		</div>
	);
};
export default Navigation;
