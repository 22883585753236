import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Card from "../UI/Card";
import { Button, Grid, TextField, Typography } from "@mui/material";
//npm install quill
import { Editor } from "primereact/editor";
import { GetNameFromInfo } from "../../utils/Helper";
import { Dialog } from "primereact/dialog";
import { dbAdd } from "../../api/db";

const AddIndividual = (props) => {
	const persondata = useLocation();
	const [data, setData] = useState();
	const navigate = useNavigate();
	const [pinfo, setPerinfo] = useState();
	const [children, setChildren] = useState();
	const [childrenIDs, setChildrenIDs] = useState();
	const [showMessage, setShowMessage] = useState(false);
	const [formData, setFormData] = useState({});
	const GetChildrenInfo = (data) => {
		let childrenstr = data.children.map((p) => p.data.name.replace(",", "<br"));
		return childrenstr;
	};
	const GetChildrenIDs = (data) => {
		let childrenids = data.children.map((p) => p.id);
		return childrenids;
	};
	const dialogFooter = (
		<div className="flex justify-content-center">
			<Button
				label="OK"
				className="p-button-text"
				autoFocus
				onClick={() => {
					setShowMessage(false);
				}}
			>
				OK
			</Button>
		</div>
	);
	useEffect(() => {
		if (persondata && persondata.state) {
			setData(persondata.state.data);
			setPerinfo(persondata.state.data.info);
			const childrenids = GetChildrenIDs(persondata.state.data);
			setChildrenIDs(childrenids);
			const childrennames = GetChildrenInfo(persondata.state.data);
			let ct2txt1 = "";
			childrennames.forEach((v, i) => {
				if (v && v != undefined) ct2txt1 += `<li>${v}</li>`;
			});
			let text = `${persondata.state.data.info}`;
			let text2 = `<h4>Children:</h4> 
			<ol>${ct2txt1} </ol>`;
			setPerinfo(text);
			setChildren(text2);
		}
	}, [persondata]);

	useEffect(() => {
		if (data) {
		}
	}, [data]);

	const formik = useFormik({
		initialValues: {
			lastname: persondata.state.data.lastname.trim(),
			id: persondata.state.data.id.trim(),
			pid: persondata.state.data.parentID.trim(),
			info: persondata.state.data.info,
			name: "",
		},
		//validationSchema: validationSchema,
		validate: (data) => {
			let errors = {};

			if (!data.lastname) {
				errors.name = "Last Name is required.";
			}
			if (!data.name) {
				errors.name = "Name is required.";
			}
			if (!data.id) {
				errors.id = "personID is required.";
			}
			if (!data.pid) {
				errors.pid = "parentID is required.";
			}
			return errors;
		},
		onSubmit: async (values) => {
			//alert(JSON.stringify(values, null, 2));
			setFormData(values);
			//let resp ="Success"
			const resp = await dbAdd(values);
			if (resp.includes("Error")) {
				alert(resp);
			} else {
				setShowMessage(true);
				formik.resetForm();
				navigate(-1);
			}
		},
	});

	return (
		<>
			<Dialog
				visible={showMessage}
				onHide={() => setShowMessage(false)}
				position="top"
				footer={dialogFooter}
				showHeader={false}
				breakpoints={{ "640px": "60vw" }}
				style={{ width: "20vw" }}
			>
				<div className="flex align-items-center flex-column pt-6 px-3">
					<i
						className="pi pi-check-circle"
						style={{ fontSize: "3rem", color: "var(--green-500)" }}
					></i>
					<h5>Add Record Successful!</h5>
				</div>
			</Dialog>
			{!data && <Card>Loading...</Card>}
			{data && (
				<Card className="cardshade">
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<Grid container spacing={1}>
							<Grid item xs={2} sm={2}>
								<Typography spacing={1} variant="caption" color="primary">
									{"Add Child Information"}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3}>
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "14px", textAlign: "left" },
									}}
									disabled
									id="id"
									name="id"
									label="ID"
									variant="standard"
									value={formik.values.id || ""}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.id && Boolean(formik.errors.id)}
									helpertext={formik.touched.id && formik.errors.id}
								/>
							</Grid>
							<Grid item xs={3} sm={3}>
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "14px", textAlign: "left" },
									}}
									disabled
									id="pid"
									name="pid"
									label="ParentID"
									variant="standard"
									value={formik.values.pid || ""}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.pid && Boolean(formik.errors.pid)}
									helpertext={formik.touched.pid && formik.errors.pid}
								/>
							</Grid>
							<Grid item xs={3} sm={3}>
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "14px", textAlign: "left" },
									}}
									fullWidth
									id="name"
									name="name"
									label="Name"
									variant="standard"
									value={formik.values.name || ""}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helpertext={formik.touched.name && formik.errors.name}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Editor
									name="info"
									style={{ height: "120px" }}
									value={formik.values.info}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.info && Boolean(formik.errors.info)}
									helpertext={formik.touched.info && formik.errors.info}
									onTextChange={(e) => {
										setPerinfo(e.textValue);
										formik.setFieldValue("info", e.textValue);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Editor value={children} disabled showHeader={false} />
							</Grid>
						</Grid>
						<div className="pt-2 border-round">
							<Button type="submit" variant="outlined" size="medium">
								Submit
							</Button>
						</div>
					</form>
				</Card>
			)}
		</>
	);
};

export default AddIndividual;
