import React from 'react';

export const PhotoCard = ({ owner, filename, id, imageUrl, onClick }) => {
    return (
        <div class="w-3 rounded overflow-hidden shadow-lg m-1">
            <img key={id} className="w-full h-64 object-center" src={imageUrl} onClick={onClick} />
            <div className="px-1 py-1">
                <div class="font-regular text-xs mb-1">{id}:{filename}</div>
            </div>
        </div>
    );
}