export const INDIVIDUAL_COLUMNS = [
	// {
	// 	headerName: "Lastname",
	// 	field: "lastname",
	// 	sortable: true,
	// 	filter: true,
	// 	width: 30,
	// 	resizable: true,
	// 	hide: true,
	// },
	{
		headerName: "Name",
		field: "name",
		sortable: true,
		filter: true,
		width: 30,
		resizable: true,
	},
	{
		headerName: "ID",
		field: "personID",
		sortable: true,
		filter: true,
		width: 50,
		resizable: true,
		sort: "asc",
	},
	{
		headerName: "Parent ID",
		field: "parentID",
		sortable: true,
		filter: true,
		width: 50,
		resizable: true,
	},
	{
		headerName: "Info",
		field: "personinfo",
		sortable: false,
		filter: false,
		width: 300,
		resizable: true,
		// colDef = {
		// 	getQuickFilterText: () => ''
		// }

		// valueGetter: (params) => {
		// 	try {
		// 		let str = "ND";
		// 		if (params.data.personinfo) {
		// 			str = params.data.personinfo;
		// 			return str;
		// 		}
		// 	} catch (exception) {
		// 		return params.data.personinfo;
		// 	}
		// },
	},
];
