//// copied from Ken
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Button } from '@mui/material'
import { Typography } from '@mui/material'

const title = ['Info', 'Warning', 'Error']
export const SEVERITY = {
   info: 0,
   warn: 1,
   error: 2,
}

function Modal({ setOpenModal, headerstr, bodystr }) {
   return (
      <Dialog
         open={setOpenModal}
         onClose={() => {
            setOpenModal(false)
         }}
      >
         <DialogTitle>{headerstr}</DialogTitle>
         <DialogContent>
            <Typography style={{ whiteSpace: 'pre-line' }}>{bodystr}</Typography>
         </DialogContent>
         <DialogActions>
            <Button
               onClick={() => {
                  setOpenModal(false)
               }}
            >
               Okay
            </Button>
         </DialogActions>
      </Dialog>
   )
}
export default Modal
