import React from 'react'
import classes from './ButtonIcon.css'
import './ButtonIcon.css'

const ButtonIcon = (props) => {
   return (
      <button
         type={props.type || "button"}
         className={`${classes.button} ${props.className}`}
         onClick={props.onClick}
         disabled={props.disabled}>
         {props.iconl && <props.iconl className='mr-2' />}
         <span className={classes.button__text}>{props.text}</span>
         <span className={classes.button__icon}> <i className={props.icon} /></span>
         {props.iconr && <props.iconr className='ml-2' />}
      </button>
   )
}

export default ButtonIcon
