import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Card from "../UI/Card";
import { Divider, Grid2, TextField, Typography } from "@mui/material";
import Button from "../UI/Button";
import { Editor } from "primereact/editor";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { dbModify, dbDelete, dbQuery } from "../../api/db";
import { ProgressBar } from "primereact/progressbar";
import UploadImage from "./UploadImage";
import Preview from "./Preview";
import { Link } from "react-router-dom";
import useZustandToken from "../useZustandToken";
import { CreateDataTree, GetLastnameFromID, GetMaterialUIDate } from "../../utils/Helper";
import { BuildDataFromRawData } from "../../utils/dbHelper";
import { debug } from "../../utils/ConsoleHelper";
import { v4 as uuidv4 } from 'uuid';
import { FaFileImport } from "react-icons/fa6";
import ButtonIcon from "../UI/ButtonIcon";
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
//import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser'

const Individual = (props) => {
	const url_location = useLocation();
	const [data, setData] = useState();
	const navigate = useNavigate();
	const [personInfo, setPerinfo] = useState();
	const [children, setChildren] = useState();
	const [childrenIDs, setChildrenIDs] = useState();
	const [showMessage, setShowMessage] = useState(false);
	const [formData, setFormData] = useState({});
	const [uploading, setUploading] = useState();
	const { token, setToken } = useZustandToken()
	const { spouseID, pid } = useParams();
	const [statusMsg, setStatusMsg] = useState();
	const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
	let personid = "";
	const { id } = useParams();
	const { setUpdate } = useZustandToken()
	//debug(id)
	const { clearalbum } = props
	const fileInputRef = useRef()
	const [htmlContent, setHtmlContent] = useState()
	const [rawhtmlContent, setRawHtmlContent] = useState()

	const GetPersonID = (pstr) => {
		let myid = ''
		const parts = pstr.split('/')
		for (let i = 0; i < parts.length; i++) {
			let e = parts[i]
			if (e !== 'person' && e != '') {
				myid = e
				break
			}
		}
		return myid
	}

	if (url_location && url_location.search) {
		personid = new URLSearchParams(url_location.search).get('id')
	}
	else if (id) {
		//  ex: person/LU_1_1_2_3
		if ((id === 'person') && url_location.search)
			personid = new URLSearchParams(url_location.search).get('id')
		else
			personid = id;
	}
	else if (url_location && url_location.pathname) {
		//// ex: person?id=LU_1_1_2_3
		personid = GetPersonID(url_location.pathname)
		debug(personid)
	}
	else {
		//// ex: person?id=LU_1_1_2_3
		personid = new URLSearchParams(url_location.search).get('id')
	}

	const GetChildrenInfo = (data) => {
		let childrenstr = []

		if (data?.children && data?.children.length > 0) {
			childrenstr = data.children.map((p) =>
				p.data.name.replace(",", "<br>")
			);
		}
		return childrenstr;
	};

	const setUploadImage = (ev) => {
		console.log(ev);
		setUploading(ev);
	};

	const deleteImage = (id) => {
		if (token && token?.token === "admin") {
			if (imagesPreviewUrls.length > 0) {
				const filterImages = imagesPreviewUrls.filter(
					(image) => image.id !== id
				);
				setImagesPreviewUrls(filterImages);
			}
		}
	};
	//// work with confirmation dialog
	const toast = useRef(null);

	const accept = (e) => {
		DeleteRecord(data);
		toast.current.show({
			severity: "info",
			summary: "Confirmed",
			detail: "You have accepted",
			life: 3000,
		});
	};
	const reject = () => {
		toast.current.show({
			severity: "warn",
			summary: "Rejected",
			detail: "You have rejected",
			life: 3000,
		});
	};
	const confirm_delete = (e) => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};
	const confirm_import = (e) => {
		confirmDialog({
			message: "Do you want to Import?",
			header: "Import Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};
	//// end - work with confirmation dialog

	const GetChildrenIDs = (data) => {
		let childrenids = ''
		if (data?.children && data?.children.length > 0) {
			childrenids = data?.children.map((p) => p.id);
		}
		return childrenids;
	};

	const dialogFooter = (
		<div className="flex justify-content-center">
			<Button
				text="OK"
				className="p-button-text"
				autoFocus
				onClick={() => {
					setShowMessage(false);
				}}
			>
				OK
			</Button>
		</div>
	);

	useEffect(() => {
		if (personid) {
			console.log("🚀 ~ useEffect ~ personid:", personid);
			const tblstr = GetLastnameFromID(personid)
			getPersonData(tblstr, personid);
			if (clearalbum) setImagesPreviewUrls([])
		}
	}, [personid]);

	useEffect(() => {
		if (clearalbum) setImagesPreviewUrls([])
	}, [clearalbum])

	// using path /person?id=personID
	const getPersonData = async (tblstr, personid) => {
		let data = [];
		if (tblstr && personid) {
			data = await dbQuery(`Select * from ${tblstr} order by personID ASC`);
			const pdata = await dbQuery(`Select * from ${tblstr} where personID="${personid}"`);
			if (personid && pdata.length <= 0) {
				alert(`Cannot find person with id = ${personid}`)
			}
			else {
				if (data) {
					const dbdata = BuildDataFromRawData(data);
					if (dbdata.some((e) => e.id === personid)) {
						const tree = CreateDataTree(dbdata, personid, "");
						console.log("🚀 ~ getData ~ tree:", tree);
						const persondata1 = { data: tree[0] };
						setData(persondata1.data);
						SetPersonalData(persondata1);
					}
				}
			}
		}
	};

	const getData = async (tblstr, rootid) => {
		let data = [];
		if (tblstr) {
			data = await dbQuery(`Select * from ${tblstr} order by personID ASC`);
			if (data) {
				const dbdata = BuildDataFromRawData(data);
				if (dbdata.some((e) => e.id === rootid)) {
					const tree = CreateDataTree(dbdata, rootid, "");
					console.log("🚀 ~ getData ~ tree:", tree);
					const persondata1 = { data: tree[0] };
					setData(personInfo.data);
					SetPersonalData(persondata1);
				}
			}
		}
	};

	useEffect(() => {
		console.log(data);
		const d = new FileReader()
		if (data) {
			initialValues = {
				lastname: data.lastname,
				id: data.id,
				pid: data.parentID,
				info: data.info,
				name: data.name,
				deceased: data.deceased,
				spouse: data.spouse,
				spid: data.spouseID,
			};
		}
	}, [data]);

	useEffect(() => {
		if (spouseID && pid) {
			console.log("🚀 ~ useEffect ~ spouseID:", spouseID);
			const lastname = GetLastnameFromID(spouseID);
			if (lastname) {
				getData(`${lastname}`, pid);
			}
		}
	}, [spouseID, pid]);

	const SetPersonalData = (pdata) => {
		if (pdata) {
			setData(pdata.data);
			setPerinfo(pdata.data.info);
			const children = pdata.children || pdata.data
			const childrenids = GetChildrenIDs(children);
			setChildrenIDs(childrenids);
			const childrennames = GetChildrenInfo(pdata.data);
			let ct2txt1 = "";
			childrennames?.forEach((v, i) => {
				if (v && v != undefined) ct2txt1 += `<li>${v}</li>`;
			});
			let text = `${pdata.data.info}`;
			let text2 = `<h4>Children:</h4> 
			<ol>${ct2txt1} </ol>`;
			setPerinfo(text);
			setChildren(text2);
		}
	};

	useEffect(() => {
		if (url_location && url_location.state) {
			setImagesPreviewUrls([])
			SetPersonalData(url_location.state);
		}
	}, [url_location]);

	let initialValues = {
		lastname: data?.lastname,
		id: data?.id,
		pid: data?.parentID,
		info: data?.info,
		name: data?.name,
		deceased: data?.deceased,
		spouse: data?.spouse,
		spid: data?.spouseID,
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validate: (data) => {
			let errors = {};

			if (!data.lastname) {
				errors.lastname = "Last Name is required.";
			}
			if (!data.name) {
				errors.name = "Name is required.";
			}
			if (!data.id) {
				errors.id = "personID is required.";
			}
			if (!data.pid) {
				errors.pid = "parentID is required.";
			}
			return errors;
		},
		onSubmit: async (values) => {
			setFormData(values);
			const error = await ModifyRecord(values);
			const errstr = error?.message || error
			if (!errstr?.includes("fail") && !errstr.includes("Error")) {
				setStatusMsg("Operation Successful!");
				setShowMessage(true);
				setUpdate()
			} else {
				setStatusMsg(`Operation Failed! Error=${error}\n${error?.response?.statusText}`);
				setShowMessage(true);
			}
		},
	});

	const DispTree = (e) => {
		//const to_tree_link = `../tree/${data.id}/${data.id}`;
		const to_tree_link = `../tree`;
		data.selected = true;
		navigate(to_tree_link, {
			state: {
				data: data,
				modify: false,
				viewonly: true,
			},
		});
	};

	const ModifyRecord = async (e) => {
		const mdata = {
			id: e.id.trim(),
			pid: e.pid.trim(),
			lastname: e.lastname.trim(),
			info: e.info,
			name: e.name.trim(),
			deceased: e?.deceased,
			spouse: e?.spouse || "",
			spid: e?.spid || "",
		};
		const resp = await dbModify(mdata);
		if (resp.includes("Success")) setUpdate()
		return resp;
	};

	const GetOldestChild = (childrenIDs) => {
		let oldest = 0;
		childrenIDs.forEach((c) => {
			const ss = c.split("_");
			const li = ss[ss.length - 1];
			if (li > oldest) oldest = li;
		});
		return oldest;
	};

	const AddRecord = (e) => {
		let oldest = 0;
		if (childrenIDs.length > 0) {
			oldest = GetOldestChild(childrenIDs);
		}
		const id = e.id.trim();
		oldest++;
		const childid = id.trim() + "_" + oldest;
		let new_data = { ...url_location.state.data };
		new_data.id = childid;
		new_data.parentID = id;
		new_data.info = "Con của Ông bà : " + new_data.data.name + ";";
		new_data.name = "";
		new_data.children = [];
		new_data.label = childid;
		console.log(new_data);
		const to_tree_link = "../addperson";
		navigate(to_tree_link, {
			state: {
				data: new_data,
				modify: false,
				viewonly: true,
			},
		});
	};

	const DeleteRecord = async (e) => {
		console.log(e);
		const mdata = {
			id: e.id,
			pid: e.pid,
		};
		const resp = await dbDelete(mdata);
		if (!resp.includes("Error")) {
			setStatusMsg("Operation Successful!");
			setShowMessage(true);
		} else {
			setStatusMsg(`Operation Failed! Error=${resp}`);
			setShowMessage(true);
		}
		return resp;
	};

	const onImportHtml = async (e) => {

		const file = e.target.files[0];
		let mlist = []
		//files.forEach((file, index) => {
		const reader = new FileReader();
		reader.onloadend = async () => {
			const result = {
				ofile: file,
				file: reader.result,
				size: file.size,
				filename: file.name,
			};
			const response = await fetch(file);
			const moddata = result.file.split(',')[1]
			const decodedString = atob(moddata);
			debug("decodedString => \n", decodedString)
			setRawHtmlContent(parse(decodedString))
			const contentBlocks = convertFromHTML(moddata);
			const contentState = ContentState.createFromBlockArray(contentBlocks);
			const content = convertToRaw(contentState);
			let res_text = await response.text();
			debug("res_text => \n", res_text)
			setHtmlContent(decodedString)

			const contentHTML = convertFromHTML(res_text);
			const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
			const html = JSON.stringify(convertToRaw(state));

		};
		reader.readAsDataURL(file);
	}

	const onEditorChange = (editorContent) => {
		console.log(draftToHtml(editorContent))
		setHtmlContent(editorContent);
	};

	return (
		<>
			<Toast ref={toast} />
			<ConfirmDialog />
			<Dialog
				visible={showMessage}
				onHide={() => setShowMessage(false)}
				position="top"
				footer={dialogFooter}
				showHeader={false}
				breakpoints={{ "640px": "60vw" }}
				style={{ width: "20vw" }}
			>
				<div className="flex align-items-center flex-column pt-6 px-3">
					<i
						className="pi pi-check-circle"
						style={{ fontSize: "3rem", color: "var(--green-500)" }}
					></i>
					<h5>{statusMsg}</h5>
				</div>
			</Dialog>
			{!data && <Card>Loading...</Card>}
			{data && (
				<Card className="cardshade">
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<div className="pt-2 mb-2">
							<Button
								variant="outlined"
								onClick={DispTree}
								text="Family Tree"
							></Button>
							{token && token?.token === "admin" && (
								<>
									<Button
										text="Add Child"
										variant="outlined"
										onClick={() => AddRecord(formik.values)}
									></Button>
									<Button
										text="Update"
										type="submit"
										variant="outlined"
									></Button>
									<Button
										text="Delete"
										onClick={() => confirm_delete(formik.values)}
										icon="pi pi-times"
										variant="outlined"
										label="Delete"
									></Button>
									{/* <ButtonIcon
										className="fa-solid fa-magnifying-glass ml-1 mr-2"
										type='file'
										text="Import"
										onClick={() => fileInputRef.current.click()}
										iconl={FaFileImport}
									></ButtonIcon> */}
									<input onChange={onImportHtml} multiple={false} ref={fileInputRef} type='file' hidden />
								</>
							)}
						</div>
						<Divider className="mb-2" />
						<Grid2 container spacing={1}>
							<Grid2 size="auto" >
								<Typography spacing={1} variant="caption" color="primary">
									{"Person Information"}
								</Typography>
							</Grid2>
							<Grid2 size="auto">
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "12px", textAlign: "left" },
									}}
									disabled
									id="id"
									name="id"
									label="ID"
									variant="standard"
									value={formik.values.id}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.id && Boolean(formik.errors.id)}
									helpertext={formik.touched.id && formik.errors.id}
								/>
							</Grid2>
							<Grid2 size="auto">
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "12px", textAlign: "left" },
									}}
									disabled
									id="pid"
									name="pid"
									label="ParentID"
									variant="standard"
									value={formik.values.pid}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.pid && Boolean(formik.errors.pid)}
									helpertext={formik.touched.pid && formik.errors.pid}
								/>
							</Grid2>
							<Grid2 size="auto">
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "14px", textAlign: "left" },
									}}
									fullWidth
									disabled={token && token?.token === "admin" ? false : true}
									id="name"
									name="name"
									label="Name"
									variant="standard"
									value={formik.values.name}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helpertext={formik.touched.name && formik.errors.name}
								/>
							</Grid2>
							<Grid2 size="auto">
								<TextField
									InputLabelProps={{ shrink: true }}
									type="date"
									inputProps={{
										style: { fontSize: "14px", textAlign: "left" },
									}}
									disabled={token && token?.token === "admin" ? false : true}
									id={uuidv4()}
									key="deceased"
									name="deceased"
									label="Memorial Day"
									variant="standard"
									//defaultValue={formik.values.deceased}
									value={formik.values.deceased}
									onChange={formik.handleChange}
									//onBlur={formik.handleBlur}
									error={formik.touched.deceased && Boolean(formik.errors.deceased)}
									helpertext={formik.touched.deceased && formik.errors.deceased}
								/>
							</Grid2>
							<Grid2 size="auto">
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "14px", textAlign: "left" },
									}}
									disabled={token && token?.token === "admin" ? false : true}
									fullWidth
									id="spouse"
									name="spouse"
									label="Spouse Name"
									variant="standard"
									value={formik.values.spouse}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.spouse && Boolean(formik.errors.spouse)}
									helpertext={formik.touched.spouse && formik.errors.spouse}
								/>
							</Grid2>
							<Grid2 size="auto">
								<TextField
									InputLabelProps={{ shrink: true }}
									inputProps={{
										style: { fontSize: "12px", textAlign: "left" },
									}}
									disabled={token && token?.token === "admin" ? false : true}
									id="spid"
									name="spid"
									label="SpouseID"
									variant="standard"
									value={formik.values.spid}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.spid && Boolean(formik.errors.spid)}
									helpertext={formik.touched.spid && formik.errors.spid}
								/>
							</Grid2>
							<Grid2 size={12}>
								{/* <MUIRichTextEditor
									maxLength
									toolbar={false}
									defaultValue={htmlContent}
									onChange={(value) => {
										const content = JSON.stringify(value.getCurrentContent());
										setHtmlContent(content);
									}}
								/> */}
								{/* <div>
									{rawhtmlContent && <iframe src={rawhtmlContent} width="100%" height="700px" />}
								</div> */}
							</Grid2>
							{/* <Grid2 size={12}>
								<div className="demo-editorSection">
									<div className="demo-editorWrapper">
										<Editor
											rawContentState={htmlContent || data.info || formik.values.info}
											toolbarClassName="demo-toolbar"
											wrapperClassName="demo-wrapper"
											editorClassName="demo-editor"
											onChange={onEditorChange}
											toolbarAlwaysVisible
										/>
									</div>
								</div>
							</Grid2> */}
							<Grid2 size={12}>
								<Editor
									readOnly={(token && token?.token === "admin") ? false : true}
									name="info"
									value={htmlContent || data.info || formik.values.info}
									onChange={formik.handleChange}
									//onBlur={formik.handleBlur}
									error={formik.touched.info && Boolean(formik.errors.info)}
									helpertext={formik.touched.info && formik.errors.info}
									onTextChange={(e) => {
										setPerinfo(e.textValue);
										formik.setFieldValue("info", e.htmlValue);
									}}
								/>
							</Grid2>
							<Grid2 size={12}>
								{url_location?.state?.data?.spouseID && (
									<div style={{ fontSize: "14px", fontFamily: "inherit" }}>
										Spouse: {url_location?.state?.data?.spouse}
									</div>
								)}
							</Grid2>
							<Grid2 size={12}>
								{url_location?.state?.data?.spouseID && (
									<Link
										style={{ fontSize: "14px", fontFamily: "inherit" }}
										to={{
											name: "",
											id: url_location?.state?.data?.spouseID,
											state: url_location?.state?.data,
											pathname: `/tree/${url_location?.state?.data?.spouseID}/${url_location?.state?.data?.spouseID}`,
										}}
									>
										Spouse Family Tree: {url_location?.state?.data?.spouseID}
									</Link>
								)}
							</Grid2>
							<Grid2 size={12}>
								{url_location?.state?.data?.spouseID && (
									<Link
										style={{ fontSize: "14px", fontFamily: "inherit" }}
										to={{
											name: "",
											id: url_location?.state?.data?.spouseID,
											state: url_location?.state?.data,
											imagesPreviewUrls: [],
											pathname: `/person/${url_location?.state?.data?.spouseID}/${url_location?.state?.data?.spouseID}`,
										}}
									>
										Spouse Parent: {url_location?.state?.data?.spouseID}
									</Link>
								)}
							</Grid2>
							<Grid2 size={12} >
								{"Children:"}
								<ol>
									{data &&
										data?.data &&
										data.children.map((p, i) => (
											<li key={i}>
												<Link
													id={i}
													key={i}
													style={{ fontSize: "14px", fontFamily: "inherit" }}
													to={{
														name: p.name,
														id: p.id,
														state: p,
														pathname: `/tree/${p.id}/${p.parentID}/${p.lastname}`,
														//pathname: `${p.id}`,
													}}
												>
													{p.data.name}
												</Link>
											</li>
										))}
								</ol>
							</Grid2>
						</Grid2 >
					</form >
					<Divider className="mb-2" />
					<Typography variant="h7">Photo Album</Typography>
					<Grid2 container spacing={1}>
						<Grid2 size={12}>
							{uploading && (
								<div className="card">
									<ProgressBar
										mode="indeterminate"
										style={{ height: "6px" }}
									></ProgressBar>
								</div>
							)}
						</Grid2>
						<Grid2 size={12}>
							<Preview
								deleteImage={deleteImage}
								imagesPreviewUrls={imagesPreviewUrls}
								setImagesPreviewUrls={setImagesPreviewUrls}
								personid={personid || data.id}
							/>
						</Grid2>
						{token && token?.token === "admin" && (
							<Grid2 size={12}>
								<UploadImage
									imagesPreviewUrls={imagesPreviewUrls}
									setImagesPreviewUrls={setImagesPreviewUrls}
									persondata={data}
									setUploading={setUploadImage}
								/>
							</Grid2>
						)}
					</Grid2>
				</Card >
			)}
		</>
	);
};

export default Individual;
