import { Divider, Typography } from '@mui/material';
import React from 'react';
import Card from '../UI/Card';
import famtreeeditor from "../assets/famtreeeditor.png"

export const LoadingUsage = () => {
   return (
      <Card>
         <Typography color='darkgreen' className="h3 m-2">FamTree Usage (admin only)</Typography>
         <div className='mt-2'>
            <Typography className="h4 m-2">
               {`Instruction for entering infomation for a person (either add or modify).
            Below is a screeshot of a person's information page`}
            </Typography>
            <Divider className="mb-4" />
            <ul className="mb-2">
               <li>To put a link to a directory of images, add the link as follow in the person's information section:
                  <b>posts?dir=name-of-the-directory</b>. The latter will use lazy loading.
               </li>
               <br></br>
               <li>
                  The directory "name-of-the-directory" contains the images must be under server's directory <b>public/images</b>
               </li>
               <br></br>
               <li>To put a link to another person's family tree:
                  <b>tree/LU_1_1_2_3/LU_1_1_2_3</b> where LU_1_1_2_3 is the person's ID
               </li>
               <br></br>
               <li>To put a link to another person:
                  <b>person?id=LU_1_1_2_3</b> where LU_1_1_2_3 is the person's ID
               </li>
            </ul>
            <img className="p-2" width="80%" height="80%" src={famtreeeditor} alt="famtreeeditor" />;
            <br></br>
         </div>
      </Card>
   );
}
//http://localhost:3334/tree/LU_1_1_2_3/LU_1_1_2_3
//http://localhost:3334/person/LU_1_1_2_3/LU_1_1_2_3